import { Box, Grid } from '@mui/material';
import useManageProjects from 'hooks/useManageProjects';

import {
  useGetClientProjectInformationQuery,
  useGetAllCountersQuery,
  useGetAllLatestCandidatePositionsQuery,
  useGetAllLatestCandidatesQuery,
} from 'api/api';
import { StatisticsWidget, PositionsWidget, ApplicantsWidget, CardsWidgetTasView } from '@nexxus/components';
import { useEffect } from 'react';

export default function DashboardSummaryRedesign() {
  const { data: graphData, isLoading: isGraphLoading } = useGetClientProjectInformationQuery();
  const { data: latestPositions, isLoading: isLoadingLatestPositions } = useGetAllLatestCandidatePositionsQuery();
  const { data: latestCandidates, isLoading: isLoadingLatestCandidates } = useGetAllLatestCandidatesQuery();
  const { data: countersResut } = useGetAllCountersQuery();
  const { projects, setFilters } = useManageProjects();

  useEffect(() => {
    setFilters((prev) => ({ ...prev, hideArchiveCustomers: true }));
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <StatisticsWidget
            title="Statistics"
            positions={graphData?.data || []}
            isLoading={isGraphLoading}
            counters={countersResut?.counters || []}
            projects={projects || []}
          />
          <br />
          <PositionsWidget title="Latest Positions" positions={latestPositions || []} isLoading={isLoadingLatestPositions} isTasAdmin />
        </Grid>
        <Grid item xs={12} md={6}>
          <ApplicantsWidget applicants={latestCandidates || []} isLoading={isLoadingLatestCandidates} isTasAdmin/>
        </Grid>
        <Grid item xs={12} md={12}>
          <CardsWidgetTasView counters={countersResut?.counters || []} handleCallbackPipelineCard={() => {}} />
        </Grid>
      </Grid>
    </Box>
  );
}
